import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component( {
  selector: 'app-loading',
  template: ``,
  styleUrl: './loading.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class LoadingComponent {
  @HostBinding( 'class.small' )
  @Input( { transform: booleanAttribute } )
  small = false;

  @HostBinding( 'class.light' )
  @Input( { transform: booleanAttribute } )
  light = false;
}
